import { useEffect, useState } from 'react'
import { formatNumber } from '@wh/common/chapter/lib/formatter'
import { AutoMotorAdTypeCounters, getAutoMotorAdTypeCounters } from '@bbx/search-journey/common/api/searchApiClient'

const formatCount = (count: number | undefined) => (typeof count === 'undefined' || count <= 0 ? '' : formatNumber(count) ?? '')

export const useAdTypeCounters = (
    initialCounters: AutoMotorAdTypeCounters,
): {
    cars: string
    motorbikes: string
    trucks: string
    caravans: string
} => {
    const [currentCounters, setCurrentCounters] = useState<AutoMotorAdTypeCounters>(initialCounters)

    useEffect(() => {
        async function initialSearch() {
            try {
                const counters = await getAutoMotorAdTypeCounters()
                setCurrentCounters(counters)
            } catch (error) {
                // ignore silently
            }
        }

        initialSearch()
    }, [])

    return {
        cars: formatCount(currentCounters?.cars),
        motorbikes: formatCount(currentCounters?.motorbikes),
        trucks: formatCount(currentCounters?.trucks),
        caravans: formatCount(currentCounters?.caravans),
    }
}
